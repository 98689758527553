import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Test() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('http://localhost/admin/CategoryApi.php')
      .then(response => {
        console.log('Response:', response); // Log the full response for debugging
        if (response.data.success) {
          setData(response.data.data);
        } else {
          setError('Failed to fetch data');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(`Error fetching data: ${error.message}`);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Category List</h1>
      <ul>
        {data.map(item => (
          <li key={item.id}>
            <h2>{item.name}</h2>
            <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
            <img src={`http://localhost/admin/images/${item.image}`} alt={item.name} />
            <p>Status: {item.status === "1" ? "Active" : "Inactive"}</p>
            <p>Date: {item.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Test;

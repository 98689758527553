import React from 'react'
import './Socialmedia.css'

const Socialmedia = () => {
  return (
    <>
      <div id="wp">
        <div>
          <a href="https://wa.me/919999010957" className="whatapp" target="blank">
          <i className='bx bxl-whatsapp bx-tada' ></i> Whatsapp<span>Whatsapp</span>
          </a>
        </div>
      </div>
      <div id="cll">
        <div>
          <a href="tel:+91-9999010957" className="insta" target="blank">
          <i className='bx bx-phone-call bx-tada' ></i> Call Us <span>Call US</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Socialmedia

import React from "react";
// import Heading from "../common/Heading";
import "./about.css";
import { Link } from "react-router-dom";
import img from "./about.png";

const About = () => {
  return (
    <>
      {/* about us section start */}
<section className="about_us_section">
  <div className="container">
    <div className="row">
      {/* col start */}
      <div className="col-lg-5 col-md-6 col-sm-12 col-12">
        <div
          className="about_img_main_div"
          data-aos="fade-up"
          data-aos-duration={1000}
          data-aos-offset={0}
        >
          <div className="about_vd_div">
            <img
              src={img}
              alt="About Us"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      {/* col end */}
      {/* col start */}
      <div className="col-lg-7 col-md-6 col-sm-12 col-12">
        <div
          className="about_content_div"
          data-aos="fade-down"
          data-aos-duration={1600}
          data-aos-offset={0}
        >
          <h1>
            Welcome to<span> Beringen Flooring</span>{" "}
          </h1>
          <h3 style={{ color: "rgb(240 124 0)" }}>About Us</h3>
          <p style={{ textAlign: "justify" }}>
            Berringen Flooring epitomizes excellence in flooring, stairs, and interior solutions, where a fusion of enduring design, impeccable artistry, and unmatched quality converge to transform every aspect of your living space.
          </p>
          <b>Our Commitment</b>
          <p style={{ textAlign: "justify" }}>
            Central to our dedication to perfection is our strategic approach to procurement, manufacturing, and customer care. Our journey begins in the heart of Europe, where we carefully select wood renowned for its unparalleled quality and sustainable forestry practices.
          </p>
          <p style={{ textAlign: "justify" }}>
  Once these premium materials are secured, we meticulously craft our products in the finest mills, leveraging generations of woodworking expertise. These mills, steeped in a tradition of precision and artistry, play an indispensable role in refining raw materials into the exquisite flooring that defines our brand.
</p>
        </div>
      </div>
      
      {/* col end */}
      {/* col s */}
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div
          className="about_content_div"
          data-aos="fade-up"
          data-aos-duration={1600}
          data-aos-offset={0}
        >
          <b>Our Global Reach</b>
          <p style={{ textAlign: "justify" }}>
            With a steadfast dedication to showcasing the timeless allure of the finest craftsmanship, our vision transcends borders to cater globally. In our pursuit of accessibility and tailored service, we've made strategic partnerships with selected professionals and entities worldwide. These collaborations stand as extensions of our unwavering commitment to customer delight, facilitating the dissemination of Berringen Flooring's elegance into homes, hospitality venues, and commercial spaces throughout the continent.
          </p>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
{/* about us section end */}
      {/* ====================why choose us section================= */}
      <section className="why_choose_us_section">
        <div className="container">
          <div className="row align-items-center">
            {/* col s */}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div
                className="why_choose_txt"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-offset={1}
                data-aos-duration={800}
              >
                <h4>Why Choose Beringen Flooring?</h4>
                <p>
                  <b >
                    We always believe in offering high-grade quality flooring
                    solutions to our valued clients all across the market.
                  </b>
                </p>
              </div>
              {/* why_us flx div */}
              <div
                className="why_choose_us_div_flx"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-offset={1}
                data-aos-duration={1000}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bx bx-building-house" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="why_us_div_txt_div">
                      <h5>Innovation</h5>
                      <p style={{ textAlign: "justify" }}>
                        Our infrastructure is equipped with the latest
                        technology to produce high-quality flooring. We
                        continuously upgrade our infrastructure to meet the
                        rising demands in the flooring industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bx bx-comment-detail" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="why_us_div_txt_div">
                      <h5>Commitment</h5>
                      <p style={{ textAlign: "justify" }}>
                        We are committed to increasing efficiency and raising
                        the standard of living. We stand by our products and
                        services and ensure they meet the expectations of our
                        clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* end */}
              {/* why_us flx div */}
              <div
                className="why_choose_us_div_flx"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-offset={1}
                data-aos-duration={500}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bx bx-unite" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="why_us_div_txt_div">
                      <h5>Quality Assurance</h5>
                      <p style={{ textAlign: "justify" }}>
                        We ensure all our flooring solutions meet the highest
                        quality standards. Our approach is focused on customer
                        satisfaction, and we strive to provide products that aid
                        in better living spaces and reduced maintenance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end */}
            </div>
            {/* col end */}
            {/* col s */}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="why_us_img_div">
                <img
                  src="https://images.unsplash.com/photo-1561297331-a9c00b9c2c44?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxNDEzNDF8MHwxfHNlYXJjaHw1fHxjYXJwZW50ZXJ8ZW58MXx8fHwxNzAzMTY1NzgwfDA&ixlib=rb-4.0.3&q=85&w=600&dpr=2"
                  alt
                  className="img-fluid"
                />
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

import React from "react"
import Heading from "../../common/Heading"
import "./recent.css"
import RecentCard from "./RecentCard"
// import Hardwares from "./Hardwares"
// import Planks from "./Planks"


const Recent = () => {
  return (
    <>
      <section className='recent padding'>
        <div className='container'>
          <Heading title='Products' subtitle='We offer an exceptional range of products to meet your needs..' />
          <RecentCard />
        {/* <Hardwares /> */}
        </div>
      </section>
    </>
  )
}

export default Recent

import React from "react"
import Hero from "./hero/Hero"
import About from "../about/About"
// import SliderComponent from "./slider img/SliderComponent"

import Awards from "./Why us/Awards"

import Recent from "./Services/Recent"
import Team from "./testimonials/Team"


const Home = () => {
  return (
    <>

      <Hero />
      <About />
      {/* <SliderComponent /> */}
      <Recent />
      {/* <Awards /> */}
      
      <Team />
    
      
    </>
  )
}

export default Home

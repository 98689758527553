import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Plank } from '../../data/services';
import './HardwaresProducts.css';
import { Link } from 'react-router-dom';

export const PlanksProducts = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch the product data based on the id
    const cardData = Plank.find(item => item.id === Number(id));
    setProduct(cardData);
  }, [id]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="product-details">
        <div className="product-image-container">
          <h3 style={{ textAlign: "center" }}>{product?.type}</h3>
          <img className="product-image" src={product?.cover} alt="Cover" />
        </div>
        <table className="product-info">
          <tr>
            <td>Wood Species & Colour:</td>
            <td>{product?.type}</td>
          </tr>
          <tr>
            <td>Colour:</td>
            <td>{product?.Code}</td>
          </tr>
          <tr>
            <td>Thickness:</td>
            <td>{product?.thickness}</td>
          </tr>
          <tr>
            <td>Plank Dimension:</td>
            <td>{product?.plank_dimension}</td>
          </tr>
          <tr>
            <td>Finish:</td>
            <td>{product?.finish}</td>
          </tr>
          <tr>
            <td>Joint:</td>
            <td>{product?.joint}</td>
          </tr>
          {/* <tr>
        <td>Brief:</td>
        <td>{product?.text}</td>
      </tr> */}

          <Link to="/contact">
            <button className="product-button" style={{ marginTop: "10px" }}>
              Enquire Now
            </button>
          </Link>
        </table>
      </div>
    </>
  );
};
import React, { useState } from "react";
import { list } from "../../data/Data";
import { Link } from "react-router-dom";

const RecentCard = () => {
  const [isExpanded] = useState(false);

  // const handleReadMoreClick = () => {
  //   setIsExpanded(!isExpanded);
  // }

  return (
    <>
      <div className="content grid3 mtop mobile-view">
        {list.map((val, index) => {
          const { cover, text, type } = val;
          const shortText = text.slice(0, 200) + ""; // Adjust as needed

          return (
            <div
              className="box shadow"
              key={index}
              style={{ borderRadius: "5%" }}
            >
              <div className="img">
                <a href={val.path}>
                  <img src={cover} alt="" style={{ borderRadius: "5%" }} />
                </a>
              </div>
              <div className="text" style={{ textAlign: "justify" }}>
                <div
                  className="category flex"
                  style={{ justifyContent: "center" }}
                >
                  <span style={{ marginTop: "-20px" }}>{type}</span>
                </div>
                <p>
                  <i></i> {isExpanded ? text : shortText}
                </p>
                {/* <button onClick={() => window.location.href = val.path}>
                  {"View Product"}
                </button> */}
                <Link to={val.path}>
                  <button style={{marginLeft:'80px',borderRadius:'10px',marginTop:"10px"
    }}>{"View Product"}</button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RecentCard;

import React from "react";
import "./recent.css";
import Herrings from "../Services/Herrings";
import Planks from "../Services/Planks";

const Engineered = () => {
  return (
    <>
      <section className="services mb">
        <div
          style={{
            backgroundColor: "#f07c00",
            paddingTop: "2px",
            padding: "2px",
            textAlign: "center",
            textSizeAdjust: "20px",
            color: "white",
          }}
        >
          <h2 style={{ paddingTop: "2px", marginBottom: "2px" }}>
            Engineered Flooring 
          </h2>
        </div>
        <section className="recent padding">
          <div className="container">
            <Planks />
            <br />
            {/* <Herrings /> */}
            <div className="beringen-flooring">
              <h5
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  color: "orange",
                }}
              >
                BERINGEN FLOORING - ENGINEERED FLOORING SOLUTIONS
              </h5>
              <p style={{ textAlign: "justify" }}>
                Beringen Flooring offers high-quality engineered flooring
                solutions that combine the beauty of natural wood with the
                durability and versatility of man-made materials. Our engineered
                floors are designed to withstand changes in humidity and
                temperature, making them a great choice for any room in your
                home.
              </p>

              <h5 style={{ color: "orange" }}>
                BENEFITS OF CHOOSING ENGINEERED FLOORING
              </h5>
              <div className="benefits">
                <h6 style={{ fontWeight: "600" }}>Durability:</h6>
                <p style={{ textAlign: "justify" }}>
                  Engineered floors are designed to be more stable and resistant
                  to moisture and heat compared to solid wood, making them a
                  durable choice for flooring.
                </p>

                <h6 style={{ fontWeight: "600" }}>Versatility:</h6>
                <p style={{ textAlign: "justify" }}>
                  With a wide range of finishes and styles, engineered flooring
                  can mimic the look of any type of wood, offering you a vast
                  array of design possibilities.
                </p>

                <h6 style={{ fontWeight: "600" }}>Easy Installation:</h6>
                <p style={{ textAlign: "justify" }}>
                  Engineered flooring is designed for easy installation, with
                  options for gluing, nailing, stapling, or floating the floor.
                </p>

                <h6 style={{ fontWeight: "600" }}>Value Addition:</h6>
                <p style={{ textAlign: "justify" }}>
                  Adding engineered flooring to your home not only enhances its
                  aesthetic appeal but also adds to its market value.
                </p>

                <h6 style={{ fontWeight: "600" }}>Eco-Friendly:</h6>
                <p style={{ textAlign: "justify" }}>
                  Engineered flooring uses less solid wood than solid hardwood
                  floors, making it a more eco-friendly choice in flooring.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Engineered;

import React from "react";
import "./header.css";
import Sidenavbar from "./Sidenavbar";
import "@fortawesome/fontawesome-free/css/all.css";
import img from "./image.png";
import { Link } from "react-router-dom";
import "./dropdown.css";

const Header = () => {
  return (
    <>
      {/* top bar section start */}
      <section className="topbar_section" style={{ padding: "3px" }}>
        <div className="container">
          <div className="row">
            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left_topbar_div">
               
                <a href="mailto:info@beringenflooring.com">
                  <span className="fa fa-envelope"></span>{" "}
                  info@beringenflooring.com
                </a>
              </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right_topbar_div">
                {/* <a href="javacript:void(0);"><i class='fa fa-truck'></i> Free Delivery</a>
                        <a href="javacript:void(0);"><i class='fa fa-globe'></i> Returns Policy</a> */}

                <b>Follow Us:</b>
                <a
                  href="https://www.facebook.com/profile.php?id=61559701701752"
                  target="blank"
                  className="fab fa-facebook"
                ></a>
                <a
                  href="https://www.instagram.com/beringen_flooring/"
                  target="blank"
                  className="fab fa-instagram"
                ></a>
                {/* <a href="/" className="fab fa-youtube"></a> */}
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/* top bar section end */}

      {/* navbar section start */}
      <nav className="navbar navbar-expand-lg navbar-light  sticky-top">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={img} className="img-fluid" alt="" />
          </a>
          {/* phone view icons s */}
          <div className="phone_view_icons_div">
            {/*  */}
            {/* <span className='phone_icons_nav'> */}
            {/* <a href="#enqformopen" data-bs-toggle="modal" className='bx bx-search-alt'></a>
      <a href="" className='bx bxs-user-circle'></a>
      <a href="" className='bx bx-shopping-bag  position-relative'><span class="badge position-absolute top-1 start-100 translate-middle">4</span></a></span> */}
            {/*  */}

            <a
              href="#sidenavbar"
              className="offcanvas_btn bx bx-menu-alt-right"
              data-bs-toggle="offcanvas"
              aria-controls="offcanvasScrolling"
            ></a>
          </div>
          {/* end */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About Us
                </Link>
                {/* <a className="nav-link" href="/about">
                  About Us
                </a> */}
              </li>
              <li className="nav-item">
                {/* <Link className="nav-link" to="/blog">
                  Why Us
                </Link> */}
                {/* <a className="nav-link" href="blog">
                  Why Us
                </a> */}
              </li>
              {/* dropdown menu */}
              <li className="nav-item dropdown">
                <Link className="nav-link" to="/services">
                  Products <i className="bx bx-chevron-down" />
                </Link>
                <ul className="dropdown-menu">
                  {/* sub menu */}
                  <li className="mkp-submenu">
                    <Link className="dropdown-item" to="/laminates">
                      Laminates Flooring <i className="bx bx-chevron-right" />
                    </Link>
                    <ul className="dropdown-menu sub_menu_scrolls">
                      <li>
                        <Link className="dropdown-item" to="/plankslaminate">
                          Planks Flooring
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/herringbonelaminates"
                        >
                          Herringbone Flooring
                        </Link>
                      </li>
                    </ul>
                  </li>

                  
                  {/* end submenu */}
                  <li>
                    <Link className="dropdown-item" to="/engineered">
                      Engineered Flooring
                    </Link>
                    
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/hardwood">
                      Solid Hardwood Flooring
                    </Link>
                  </li>
                </ul>
              </li>
              {/* dropdown menu end*/}

              <li className="nav-item">
                <a className="nav-link" href="/sitemap">
                  SiteMap
                </a>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/team">
                  Our Clients
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
            {/* <ul className="categories_btn">
      <li className="nav-item"><a className="nav-link" href=""> <i className='bx bx-menu-alt-left'></i> All Categories</a></li>
      </ul> */}
          </div>
        </div>
      </nav>
      {/* end navr section */}

      {/* sidenavbar start */}
      <Sidenavbar />
      {/* sidenavbar end */}
    </>
  );
};

export default Header;

import React from "react";
import "./recent.css";
// import Back from "../../common/Back"
import Hardwares from "../Services/Hardwares";

const Hardwood = () => {
  return (
    <>
      <section className="services mb">
        <div
          style={{
            backgroundColor: "#f07c00",
            paddingTop: "2px",
            padding: "2px",
            textAlign: "center",
            textSizeAdjust: "20px",
            color: "white",
          }}
        >
          <h2 style={{ paddingTop:'2px',marginBottom:'2px'}}>Solid Hardwood Flooring</h2>
        </div>
        {/* <Back name='' title='Hardwood' cover='https://precisionflooringservices.com/wp-content/uploads/2023/01/Ingo-Bartussek-shutterstock_1541423144.jpg' /> */}
        <section className="recent padding">
          <div className="container">
            <Hardwares />

            <div className="beringen-flooring">
              <h5
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  color: "orange",
                }}
              >
                BERINGEN FLOORING - SOLID HARDWOOD FLOORING SOLUTIONS
              </h5>
              <p style={{ textAlign: "justify" }}>
                Transform your living or work space with Beringen Flooring's
                premium solid hardwood flooring solutions. Our hardwood floors
                are designed to bring the timeless beauty of nature into your
                space, while offering durability and longevity. Choose from our
                diverse range of hardwood options, each crafted to meet the
                highest standards of quality and design.
              </p>

              <h5 style={{ color: "orange" }}>
                BENEFITS OF CHOOSING SOLID HARDWOOD FLOORING
              </h5>
              <div className="benefits">
                <h6 style={{ fontWeight: "600" }}>Durability:</h6>
                <p style={{ textAlign: "justify" }}>
                  Our solid hardwood floors are built to last, offering
                  resistance to wear and tear and maintaining their beauty for
                  years to come.
                </p>

                <h6 style={{ fontWeight: "600" }}>Natural Beauty:</h6>
                <p style={{ textAlign: "justify" }}>
                  With a variety of wood species, colors, and finishes, solid
                  hardwood flooring brings the unique charm and warmth of nature
                  into your space.
                </p>

                <h6 style={{ fontWeight: "600" }}>Easy Maintenance:</h6>
                <p style={{ textAlign: "justify" }}>
                  Hardwood flooring is easy to clean and maintain, helping you
                  keep your space looking its best with minimal effort.
                </p>

                <h6 style={{ fontWeight: "600" }}>Value Addition:</h6>
                <p style={{ textAlign: "justify" }}>
                  Adding solid hardwood flooring to your home or office not only
                  enhances its aesthetic appeal but also adds to its market
                  value.
                </p>

                <h6 style={{ fontWeight: "600" }}>Eco-Friendly:</h6>
                <p style={{ textAlign: "justify" }}>
                  Beringen Flooring is committed to sustainability, sourcing our
                  hardwood responsibly and ensuring our products are
                  eco-friendly.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Hardwood;

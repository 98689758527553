import React from "react";
import { useState } from "react";
import { Herringbone } from "../../data/services";
import Heading from "../../common/Heading";
import "./recent.css";
import { useHistory } from "react-router-dom";

const Herrings = () => {
  const [isExpanded] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null); // Add this line
  const history = useHistory(); // Use useHistory instead of useLocation

 const handleClick = (index) => {
  const cardData = Herringbone[index];
  history.push(`/Herringbone/${cardData?.id}`);
};
  return (
    <div className="container">
      <Heading
        title="Herringbone Flooring"
        subtitle="We offer an exceptional range of Herringbone flooring to meet your needs."
      />
      <p style={{ textAlign: "justify" }}>
        Design goes hand in hand with functionality for purposeful & better
        living. Something the Roman pioneered in their design philosophy with a
        pattern known as Herringbone. These patterns facilitated better flow of
        movement and also became an ode to man’s imagination and artistry with
        infinite customization options. Inspiration led the way and thus was
        born a modern classic among us, which we are proud of Beringen
        Herringbone Collection. A collection engineered to stability and
        durability with warranty of up to 30 years. With a thickness of 15mm &
        standardize offering of 540mm x 90mm, these engineered floors shall help
        you personalize your living space, while keeping the quintessential
        aesthetic of a home that is born of nature & is captivating.
      </p>
      <h5 style={{ color: "orange", textAlign: "center" }}>APPLICATIONS</h5>
      <div className="content grid3 mtop mobile-view">
        {Herringbone.map((val, index) => {
          const { cover, text, type, color, hoverImage } = val;
          const shortText = text.slice(0, 100) + "..."; // Adjust as needed

          return (
            <div
              className="box shadow"
              key={index}
              style={{ borderRadius: "5%" }}
              onClick={() => {
                handleClick(index);
              }}
            >
              <div className="img">
              <a href={val.path}>
                  <img
                    src={hoverIndex === index ? hoverImage : cover} // Show hoverImage when mouse is hovering over the image
                    alt=""
                    style={{ borderRadius: "5%" }}
                    onMouseEnter={() => setHoverIndex(index)} // Set hoverIndex to current index when mouse enters
                    onMouseLeave={() => setHoverIndex(null)} // Reset hoverIndex when mouse leaves
                  />
                </a>
              </div>
              <div className="text" style={{ textAlign: "justify" }}>
                <div
                  className="category flex"
                  style={{ justifyContent: "center" }}
                >
                  <span style={{ marginTop: "-20px" }}>{type}</span>
                </div>
                <p>
                  <i></i> {isExpanded ? text : shortText}
                </p>
                <span style={{ marginTop: "10px", fontSize: "15px" }}>
                  <strong>Color:</strong> {color}
                </span>
                {/* <Link to={val.path}>
                  <button>{"View Product"}</button>
                </Link> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Herrings;

import React from "react";
// import { footer } from "../../data/Data"
import "./footer.css";
import { Link } from "react-router-dom";
import image from "../footer/image.png";
import Socialmedia from "../../SocialMedia/Socialmedia";
<Link to="/contact">Contact Us Today</Link>;

const Footer = () => {
  return (
    <>
      <div>
        <footer className="footer">
          <div className="footer_overlay">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="footer_about_div">
                    <div className="footer_logo_div">
                      <a href="/">
                        <img src={image} alt="" className="img-fluid" />
                      </a>
                    </div>
                    <p style={{ fontSize: "15px", textAlign:'justify' }}>
                      Beringen Flooring is your premier destination for
                      flooring, stairs, and interior solutions, combining
                      timeless design, meticulous craftsmanship, and quality.
                      Our commitment to excellence is reflected in our strategic
                      approach to sourcing, production, and service.. . 
                      <Link className="readmore" to="about">
                        Read More
                      </Link>
                    </p>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti">
                    <h5>
                      <img src="" alt="" className="img-fluid" /> Quick Links
                    </h5>
                    <ul>
                      <li>
                        <a href="/">
                          {" "}
                          <i className="bx bx-link" /> Home
                        </a>
                      </li>
                      <li>
                        <Link to="about">
                          {" "}
                          <i className="bx bx-link" /> About Us
                        </Link>
                      </li>

                      <li>
                        <Link to="team">
                          {" "}
                          <i className="bx bx-link" /> Our Clients
                        </Link>
                      </li>
                      {/*<li><Link to="javascript:void();"> <i class='bx bx-link'></i> Brochure</Link></li>*/}
                      {/* <li>
                        <Link to="blog">
                          {" "}
                          <i className="bx bx-link" /> Why Us
                        </Link>
                      </li> */}
                      <li>
                        <Link to="contact">
                          {" "}
                          <i className="bx bx-link" /> Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti">
                    <h5>
                      <img src="" alt="" className="img-fluid" /> Products
                    </h5>
                    <ul>
                      {/* <li>
                        <Link to="services">
                          <i className="bx bx-link" />
                          Products -
                        </Link>
                      </li> */}
                      <li>
                        <Link to="laminates">
                          <i className="bx bx-link" />
                          Laminate Flooring
                        </Link>
                      </li>
                      <li>
                        <Link to="hardwood">
                          <i className="bx bx-link" />
                        Solid Hardwood Flooring
                        </Link>
                      </li>
                      <li>
                        <Link to="engineered">
                          <i className="bx bx-link" />
                          Engineered Flooring
                        </Link>
                      </li>
                      {/* <li style={{ marginLeft: '10px' }}>
                        <Link to="plank">
                          <i className="bx bx-link" />
                           - Plank Flooring
                        </Link>
                      </li>
                      <li style={{ marginLeft: '10px' }}>
                        <Link to="herringbone">
                          <i className="bx bx-link" />
                           - Herringbone Flooring
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti">
                    <h5>
                      <img src="" alt="" className="img-fluid" /> Contact Us
                    </h5>
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bx-map" style={{color:'orange'}}/>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div address-info">
                          <p>Woodpie AB Bäckadalsgatan 13 543 32, Tibro Sverige, (Sweden)</p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    {/* media div end */}
                    {/* media div start */}
                    {/* <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bxs-phone-call" style={{color:'orange'}} />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div">
                        <a href="tel: +91-9999010957"><i ></i> +91-9999010957</a>
<a href="tel: +91-9811056183"><i ></i> +91-9811056183</a>
                        </div>
                      </div>
                    </div> */}
                    {/* media div end */}
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bx-envelope-open" style={{color:"orange"}} />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div">
                          <a href="mailto:Prashant_tcc@outlook.com" className="email-link">
  <i></i> Prashant_tcc@outlook.com
</a>
                        </div>
                        <div className="footer_num_div">
                          <a href="mailto:info@beringenflooring.com" className="email-link">
  <i></i> info@beringenflooring.com
</a>
                        </div>
                      </div>
                    </div>
                    {/* media div end */}
                    {/* social icon div */}
                    {/* <div className="footer_icon_div">
                      <b className="bx bx-share-alt" />
                      {/* <Link to="/" className="bx bxl-facebook" />
                <Link to="/" className="bx bxl-instagram" /> */}
                      {/*<Link to="javascript:void();" class="bx bxl-youtube"></Link>*/}
                    {/* </div> */} 
                    {/* social icon div  end*/}
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </footer>
        {/* ===================================copyright section start================= */}
        {/* <section className="copyright_section">
          <div className="container">
            <div className="row">
             
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <p>
                  Copyright ©️ 2024 Beringen Flooring. All Right Reserved.
                  Design by{" "}
                  <a
                    href="https://www.webflowindia.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Web Flow India
                  </a>
                  .
                </p>
              </div>
             
            </div>
          </div>
        </section> */}
        {/* ===================================copyright section end================= */}
      </div>
      <Socialmedia />
    </>
  );
};

export default Footer;

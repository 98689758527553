import React from "react"
// import Back from "../common/Back"
// import RecentCard from "../home/Services/RecentCard"
// import Location from "../../components/home/Why us/Awards"
import "../home/Services/recent.css"
// import img from "../images/1.jpg"
import Awards from "../../components/home/Why us/Awards"

const Blog = () => {
  return (
    <>
       {/* ====================why choose us section================= */}
       <section className="why_choose_us_section">
        <div className="container">
          <div className="row align-items-center">
            {/* col s */}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div
                className="why_choose_txt"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-offset={1}
                data-aos-duration={800}
              >
                <h4>Why Choose Beringen Flooring?</h4>
                <p>
                  <b>
                    We always believe in offering high-grade quality flooring
                    solutions to our valued clients all across the market.
                  </b>
                </p>
              </div>
              {/* why_us flx div */}
              <div
                className="why_choose_us_div_flx"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-offset={1}
                data-aos-duration={1000}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bx bx-building-house" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="why_us_div_txt_div">
                      <h5>Innovation</h5>
                      <p>
                        Our infrastructure is equipped with the latest
                        technology to produce high-quality flooring. We
                        continuously upgrade our infrastructure to meet the
                        rising demands in the flooring industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bx bx-comment-detail" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="why_us_div_txt_div">
                      <h5>Commitment</h5>
                      <p>
                        We are committed to increasing efficiency and raising
                        the standard of living. We stand by our products and
                        services and ensure they meet the expectations of our
                        clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* end */}
              {/* why_us flx div */}
              <div
                className="why_choose_us_div_flx"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-offset={1}
                data-aos-duration={500}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="bx bx-unite" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="why_us_div_txt_div">
                      <h5>Quality Assurance</h5>
                      <p>
                        We ensure all our flooring solutions meet the highest
                        quality standards. Our approach is focused on customer
                        satisfaction, and we strive to provide products that aid
                        in better living spaces and reduced maintenance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end */}
            </div>
            {/* col end */}
            {/* col s */}
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="why_us_img_div">
                <img
                  src="https://images.unsplash.com/photo-1561297331-a9c00b9c2c44?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxNDEzNDF8MHwxfHNlYXJjaHw1fHxjYXJwZW50ZXJ8ZW58MXx8fHwxNzAzMTY1NzgwfDA&ixlib=rb-4.0.3&q=85&w=600&dpr=2"
                  alt
                  className="img-fluid"
                />
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog

import React from "react";
import { useState } from "react";
import { Plank } from "../../data/services";
import Heading from "../../common/Heading";
import "./recent.css";
import { useHistory } from "react-router-dom";

const Planks = () => {
  const [isExpanded] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null); // Add this line
  const history = useHistory(); // Use useHistory instead of useLocation

  const handleClick = (index) => {
    const cardData = Plank[index];
    history.push(`/Planks/${cardData?.id}`);
  };
  return (
    <div className="container">
      <Heading
        title="Plank Flooring"
        subtitle="We offer an exceptional range of Planks to meet your needs."
      />
      <p style={{ textAlign: "justify" }}>
        Beringen engineered flooring combines the luxury and elegance of
        hardwood with the practical benefits of high-quality engineering. It
        provides a timeless enhancement to any home, featuring rich colors,
        subtle variations, and unique grain patterns that add natural charm to
        any space. Beringen engineered flooring is not only aesthetically
        pleasing but also exceptionally durable, making it ideal for
        high-traffic areas. It maintains its beauty even in busy environments,
        enhancing the ambiance of living rooms, dining areas, lobbies, and more.
        The allure of engineered floors offers an ideal backdrop for your home's
        aesthetic appeal, seamlessly integrating with tasteful décor. With
        Beringen engineered flooring, you're investing in enduring quality and
        timeless sophistication, elevating the overall look and feel of your
        home.
      </p>
      <h5 style={{ color: "orange", textAlign: "center" }}>APPLICATIONS</h5>
      <div className="content grid3 mtop mobile-view">
        {Plank.map((val, index) => {
          const { cover, text, type, color, hoverImage } = val;
          const shortText = text.slice(0, 80) + "..."; // Adjust as needed

          return (
            <div
              className="box shadow"
              key={index}
              style={{ borderRadius: "5%" }}
              onClick={() => {
                handleClick(index);
              }}
            >
              <div className="img">
                <a href={val.path}>
                  <img
                    src={hoverIndex === index ? hoverImage : cover} // Show hoverImage when mouse is hovering over the image
                    alt=""
                    style={{ borderRadius: "5%" }}
                    onMouseEnter={() => setHoverIndex(index)} // Set hoverIndex to current index when mouse enters
                    onMouseLeave={() => setHoverIndex(null)} // Reset hoverIndex when mouse leaves
                  />
                </a>
              </div>
              <div className="text" style={{ textAlign: "justify" }}>
                <div
                  className="category flex"
                  style={{ justifyContent: "center" }}
                >
                  <span style={{ marginTop: "-20px" }}>{type}</span>
                </div>
                <p>
                  <i></i> {isExpanded ? text : shortText}
                </p>
                <span style={{ marginTop: "10px", fontSize: "15px" }}>
                  <strong>Color:</strong> {color}
                </span>
                {/* <Link to={val.path}>
                  <button>{"View Product"}</button>
                </Link> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Planks;

import React from "react";
import "./sitemap.css";

const Sitemap = () => {
  return (
    <>
      <div>
        {/*  */}
        <section className="site_map_navi">
          <div className="container">
            {/*  */}
            <div className="site_map_nav">
              <h2>Useful Links</h2>
              <div className="button-row">
                <a href="/" className="site-map-btn">
                  Home
                </a>
                <a href="about" className="site-map-btn">
                  About Us
                </a>
                <a href="blog" className="site-map-btn">
                  Why Us
                </a>
              </div>
              <div class="button-row">
                <a href="services" className="site-map-btn">
                  Products
                </a>
                <a href="team" className="site-map-btn">
                  Our Clients
                </a>
                <a href="contact" className="site-map-btn">
                  Contact Us
                </a>
              </div>
            </div>

            {/*  */}
          </div>
        </section>
        {/* our product start */}
        <section className="sitemap_section">
          <div className="container">
            <div className="sitemap_head_ti">
              <h2>Products</h2>
            </div>
            <div className="row">
              {/* col */}
              <div className="col-lg-6">
                <div className="sitemap_ul_div">
                  <ul>
                    <li>
                      <a href="services">All Products</a>
                    </li>
                    <li>
                      <a href="laminates">Laminates Flooring</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/*  */}
              {/* col */}
              <div className="col-lg-6">
                <div className="sitemap_ul_div">
                  <ul>
                    <li>
                      <a href="hardwood">Hardwood Flooring</a>
                    </li>
                    <li>
                      <a href="engineered">Engineering Flooring</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  */}
      </div>
    </>
  );
};

export default Sitemap;

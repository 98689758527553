import React from "react";
import img from "./image.png";

const Sidenavbar = () => {
  return (
    <>
      {/* side bar nav main div start */}
      <div className="sidebar_main_div">
        <div
          class="offcanvas offcanvas-start d-block d-lg-none"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabindex="-1"
          id="sidenavbar"
          aria-labelledby="sidenavbarLabel"
        >
          {/* start header */}
          <div className="offcanvas-header">
            <a className="navbar-brand" href="/">
              <img src={img} className="img-fluid" alt="" />
            </a>

            <button
              type="button"
              className="fa fa-times"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          {/* end haeder */}

          {/* offcanvas body start */}
          <div className="offcanvas-body">
            {/* sidebar navlink div start */}
            <div className="sidenav_navlink_div">
              <ul>
                <li>
                  <a href="/">
                    {" "}
                    <i className="bx bx-home"></i> Home
                  </a>
                </li>
                <li>
                  <a href="/about">
                    <i className="bx bx-book-reader"></i> About us
                  </a>
                </li>
                
                {/*  All Categories li */}
                <div className="categories_div_main">
                  <li>
                    <a href="javascript:void(0);">
                      <i className="bx bx-category-alt"></i>
                      <a href="/services">Products</a>{" "}
                    </a>{" "}
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target="#categories-menu"
                      className="bx bx-chevron-down"
                    />
                  </li>
                  <div className="collapse" id="categories-menu">
                    <div className="card card-body categories_card_body">
                      <ul className="categories_ul_list">
                        
                        <li>
                          <a href="/laminates">
                            <i className="bx bx-grid-alt"></i> Laminate Flooring
                          </a>
                        </li>
                        <li>
                          <a href="/engineered">
                            <i className="bx bx-grid-alt"></i> Engineered
                            Flooring
                          </a>
                        </li>
                        <li>
                          <a href="/hardwood">
                            <i className="bx bx-grid-alt"></i> Solid Hardwood
                            Flooring{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* All Categories drop li end */}
                <li>
                  <a href="/team">
                    <i className="bx bxs-user-detail"></i> Our Clients
                  </a>
                </li>
              </ul>
            </div>
            {/* sidebar navlink div end*/}

            {/* side bar profile link start  */}
            <div className="sidebar_profile_div_main">
              <ul>
                <li>
                  <a href="/sitemap">
                    <i className="bx bx-sitemap"></i> Site Map
                  </a>
                </li>

                <li>
                  <a href="/contact">
                    <i className="bx bxs-shopping-bags"></i> Contact us
                  </a>
                </li>
              </ul>
            </div>
            {/* side bar profile link end */}

            {/* side bar terms link start  */}
            <div className="sidebar_terms_div_main">
              <ul>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=61559701701752">
                    <i className="bx bxl-facebook"></i> Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/beringen_flooring/">
                    <i className="bx bxl-instagram"></i> Instagram
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="bx bx-phone-call"></i> +91-9999010957
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="bx bx-phone-call"></i> +91-9811056183
                  </a>
                </li>
                <li>
                  <a href="mailto:Prashant_tcc@Outlook.Com">
                    <i className="bx bx-envelope-open"></i>{" "}
                    Prashant_tcc@Outlook.Com
                  </a>
                </li>
              </ul>
            </div>
            {/* side bar terms link end */}
          </div>
          {/* offcanvas body end */}
        </div>
      </div>
      {/* side bar nav main div end */}
    </>
  );
};

export default Sidenavbar;

import React from "react";
import "./recent.css";
// import Herrings from "../Services/Herrings";
// import Simplelanks from "../Services/Simpleplanks"
import Simpleplanks from "../Services/Simpleplanks";
// import Planks from "../Services/Planks"

// import Back from "../../common/Back"

const PlanksLaminates = () => {
  return (
    <>
      <section className="services mb">
        <div
          style={{
            backgroundColor: "#f07c00",
            textAlign: "center",
            textSizeAdjust: "20px",
            color: "white",
          }}
        >
          <h2 style={{ paddingTop: "2px", marginBottom: "2px" }}>
            Laminate Flooring (Planks)
          </h2>
        </div>
        {/* <Back name='' title='Laminated' cover='https://assets-news.housing.com/news/wp-content/uploads/2022/01/28082225/Laminate-flooring-What-makes-it-an-ideal-choice-for-homes.jpg' /> */}
        <section className="recent padding">
          <div className="container">
            <Simpleplanks />
            

            <div className="beringen-flooring">
              <h5
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  color: "orange",
                }}
              >
                BERINGEN FLOORING - LAMINATE FLOORING SOLUTIONS
              </h5>
              <p>
                Elevate your living or work space with Beringen Flooring's
                premium laminate flooring solutions. Designed to combine
                durability with contemporary aesthetics, our laminate floors
                offer a perfect balance of style and functionality. Featuring an
                innovative click-lock installation system, Beringen Flooring
                makes revamping your space hassle-free and efficient. Choose
                from our diverse range of laminate options, each crafted to meet
                the highest standards of quality and design.
              </p>

              <h5 style={{ color: "orange" }}>
                BENEFITS OF CHOOSING LAMINATE FLOORING
              </h5>
              <div className="benefits">
                <h6 style={{ fontWeight: "600" }}>Durability:</h6>
                <p>
                  Engineered to withstand heavy foot traffic and daily wear, our
                  laminate floors are built to last, maintaining their allure
                  for years to come.
                </p>

                <h6 style={{ fontWeight: "600" }}>Style Flexibility:</h6>
                <p>
                  With an array of colors, patterns, and textures, laminate
                  flooring effortlessly adapts to any design scheme, providing
                  endless possibilities for personalization.
                </p>

                <h6 style={{ fontWeight: "600" }}>Easy Maintenance:</h6>
                <p>
                  Enjoy hassle-free upkeep with laminate flooring, as it
                  requires minimal maintenance to keep its pristine appearance
                  intact.
                </p>

                <h6 style={{ fontWeight: "600" }}>Budget-Friendly:</h6>
                <p>
                  Achieve the look of hardwood or stone at a fraction of the
                  cost with our affordable laminate flooring options, without
                  compromising on quality or style.
                </p>

                <h6 style={{ fontWeight: "600" }}>Eco-Friendly:</h6>
                <p>
                  Beringen Flooring is committed to sustainability, using
                  eco-friendly materials in our laminate flooring products,
                  making them a responsible choice for environmentally conscious
                  consumers.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default PlanksLaminates;

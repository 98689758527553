export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
 {
  text: "Products",
  path: "/services",
},
  {
    text: "Why us",
    path: "/blog",
  },
  {
    text: "Site Map",
    path: "/sitemap",
  },
  {
    text: "Our Clients",
    path: "/team",
  },
  {
    text: "contact",
    path: "/contact",
  },
  
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Family House",
    // total: "122 Property",
  },
  {
    cover: "../images/hero/h2.png",
    name: "House & Villa",
    // total: "155 Property",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Apartment",
    // total: "300 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Office & Studio",
    // total: "80 Property",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Villa & Condo",
    // total: "80 Property",
  },
]
//Products services-----------------------------------------------------------
export const list = [
  {
    id: 1,
    cover: "https://jumanji.livspace-cdn.com/magazine/wp-content/uploads/sites/2/2020/02/19230327/laminate-cost.jpg",
    text: "Our laminate flooring combines aesthetics and durability, perfect for high-traffic areas in your home or office.",
    type: "Laminate Flooring",
    path:"/Laminates"
  },
  {
    id: 2,
    cover: "https://precisionflooringservices.com/wp-content/uploads/2023/01/Ingo-Bartussek-shutterstock_1541423144.jpg",
    text: "Engineered flooring offers the beauty of hardwood with added durability, making it a great choice for any room & cabin.",
    type: "Engineered Flooring",
    path:"/Engineered"
  },
  {
    id: 3,
    cover: "https://www.insightflooring.com.au/wp-content/uploads/2020/02/Hurford-Roasted-Peat-Timber-Flooring-Sydney-59-Copy.jpg",
    text: "Hardwood flooring adds a touch of elegance and warmth to any space, with a variety of types and finishes available.",
    type: "Solid Hardwood Flooring",
    path:"/Hardwood"
  },
]
export const awards = [
  {
    icon: <i className='fa fa-handshake'></i>,
    num: "Consult",
    name: "Discuss needs with our flooring team.",
  },
  {
    icon: <i className='fa fa-pencil-alt'></i>,
    num: "Design",
    name: "Choose flooring type and design.",
  },
  {
    icon: <i className='fa fa-shopping-cart'></i>,
    num: "Order",
    name: "Place order for flooring materials.",
  },
  {
    icon: <i className='fa fa-building'></i>,
    num: "Install",
    name: "Professional installation of your new floor.",
  },
];

 
//clients-----------------------------------------------------------
export const team = [
  {
    name: "Liam Schmidt",
    testimonial: "Installed laminate flooring in a large office space.",
    cover:'https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png',
  },
  {
    name: "Emma Müller",
    testimonial: "Installed hardwood flooring in a spacious bungalow.",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Noah Schneider",
    testimonial: "Installed planks in a luxurious bungalow.",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Olivia Wagner",
    testimonial: "Installed herringbone flooring in a modern office.",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Lucas Fischer",
    testimonial: "Installed plank flooring in a boutique showroom.",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
];

export const footer = [
  {
    title: "Useful Links",
    text: [{ list: "Home" }, { list: "About us" }, { list: "Service" }, { list: "Why us" }, { list: "Contact us" }],
  },
  // {
  //   title: "ALL SECTIONS",
  //   text: [{ list: "Gallery" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }],
  // },
  
]

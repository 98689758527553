import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import React, { useEffect } from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
// import Pricing from "../pricing/Pricing"
import Blog from "../work/Blog"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import team from "../../components/home/testimonials/Team"

import LaminatesPage from "../home/Gallery/Laminates"
import EngineeredPage from "../home/Gallery/Engineered"
// import HardwoodPage from "../home/Gallery/Hardware"
// import PlankPage from "../home/Gallery/Plank"
import Herrings from "../home/Services/Herrings"
import Planks from "../home/Services/Planks"
import Hardwares from "../home/Services/Hardwares"
import Hardwood from "../home/Gallery/Hardwood"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import Sitemap from "../Sitemap/Sitemap"
import { HardwaresProducts } from '../home/Services/HardwaresProducts';
import { PlanksProducts } from '../home/Services/PlanksProducts';
import { SimpleplanksProducts } from '../home/Services/SimpleplanksProducts';
import { HerringsProducts } from '../home/Services/HerringsProducts';
import Test from '../home/Services/Test';
import PlanksLaminates from '../home/Gallery/PlanksLaminates';
import HerringboneLaminates from '../home/Gallery/HerringboneLaminates';




const Pages = () => {

  return (
    <>
      <Router>
      <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/blog' component={Blog} />
          {/* <Route exact path='/pricing' component={Pricing} /> */}
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/team' component={team} />
          <Route exact path='/Engineered' component={EngineeredPage} />
          <Route exact path='/laminates' component={LaminatesPage} />
          <Route exact path='/hardwood' component={Hardwood} />
          <Route exact path='/hardwood/:type' component={HardwaresProducts} />
          <Route exact path='/planks/:id' component={PlanksProducts} />
          <Route exact path='/laminatedplanks/:id' component={SimpleplanksProducts} />
          <Route exact path='/herringbone/:id' component={HerringsProducts} />
          <Route exact path='/planks' component={Planks} />
          {/* <Route exact path='/herring' component={Herring} /> */}
          <Route exact path='/herringbone' component={Herrings} />
          <Route exact path='/hardware' component={Hardwares} />
          <Route exact path='/sitemap' component={Sitemap} />
          <Route exact path='/test' component={Test} />
          <Route exact path='/plankslaminate' component={PlanksLaminates} />
          <Route exact path='/herringbonelaminates' component={HerringboneLaminates} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}
 
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}, [pathname]);

  return null;
}

export default Pages

import React from "react"
// import Heading from "../../common/Heading"
import "./hero.css"
import videos from "./videos/mobile.mp4"
import video from "./videos/desktop.mp4"

const Hero = () => {
  return (
    <>
      <section className="desktop_section_video"> 
  <div className="video_main_div">
   <video width="100%" loop muted autoPlay playsInline>
  <source src={video} type="video/mp4" />
</video>
  </div>
</section>

<section className="moblie_section_video"> 
  <div className="video_main_div">
   <video width="100%" loop muted autoPlay playsInline>
  <source src={videos} type="video/mp4" />
</video>
  </div>
</section>
{/* End Main Banner Area */}

{/* <section className="desktop_section_image"> 
        <div className="image_main_div">
          <img src='https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/Articles/types-of-hardwood-floors-2021-section-2.jpg' alt="Banner" width="100%" />
        </div>
      </section> */}

    </>
  )
}

export default Hero

//Herringbone-----------------------------------------------------------
import img1 from '../../assets/img/hb/greyherringbone.jpg'
import img2 from '../../assets/img/hb/Walnutlaminateherringbone.jpg'
import img3 from '../../assets/img/hb/oakcarbonised.jpeg'
import img4 from '../../assets/img/hb/naturalHerringbone.jpg'
import img5 from '../../assets/img/hb/classicwalnutHerringbone.webp'
// import img6 from '../../assets/img/hb/h6.jpg'
//Enginnering Planks-----------------------------------------------------------
// import img7 from '../../assets/img/laminated/p1.jpg'
import img8 from '../../assets/img/laminated/p2.jpg'
import img9 from '../../assets/img/laminated/oakenglish.jpeg'
import img10 from '../../assets/img/laminated/p4.jpg'
import img11 from '../../assets/img/laminated/oaknatural1.jpeg'
import img12 from '../../assets/img/laminated/oaknatural2.jpg'
import img27 from '../../assets/img/laminated/p13.png'
import img28 from '../../assets/img/laminated/p14.png'
import img29 from '../../assets/img/laminated/p15.png'
import img30 from '../../assets/img/laminated//28 mm sugesstion 1.jpg'
import img33 from '../../assets/img/laminated/12-3.jpg'
import img32 from '../../assets/img/laminated/EN-flooring 1.jpg'

//Hardware-----------------------------------------------------------
import img13 from '../../assets/img/hardwood/antique 3.webp'
import img14 from '../../assets/img/hardwood/oakantique2.png'
import img15 from '../../assets/img/hardwood/oakcaramel.png'
import img16 from '../../assets/img/hardwood/oakcaremal.jpg'
import img17 from '../../assets/img/hardwood/solid natural.webp'
import img18 from '../../assets/img/hardwood/okan.webp'
import img31 from '../../assets/img/hardwood/antique 2.jpg'
import img34 from '../../assets/img/hardwood/oakcaremal2.jpeg'
//Simple Planks-----------------------------------------------------------
import img19 from '../../assets/img/Simple plank/1.png'
import img20 from '../../assets/img/Simple plank/2.png'
import img21 from '../../assets/img/Simple plank/3.png'
import img22 from '../../assets/img/Simple plank/4.png'
import img23 from '../../assets/img/Simple plank/5.png'
import img24 from '../../assets/img/Simple plank/6.png'
import img25 from '../../assets/img/Simple plank/7.png'
import img26 from '../../assets/img/Simple plank/8.png'

//Herringbone-----------------------------------------------------------
export const Herringbone = [
  {
    id: 1,
    type: "Splendid Grey",
    hoverImage: img1,
    cover: img1,
    plank_dimension: `${103} * ${608} mm`,
    thickness: `${14} mm`,
    joint: "Wax coated, click Lock",
    // hoverImage: 'https://www.lamiwood.in/wp-content/uploads/2021/08/Matt-Grey-Oak-Herringbone.jpg',
    // cover: "https://www.lamiwood.in/wp-content/uploads/2021/08/Matt-Grey-Oak-Herringbone.jpg",
    text: "Herringbone Grey flooring offers a classic and versatile look, perfect for any room.",
    color: "Grey",
    Code: "Grey 2024",
  },
  {
    id: 2,
    type: "Classic Brown ",
    hoverImage: img2,
    cover: img2,
    plank_dimension: `${103} * ${608} mm`,
    thickness: `${14} mm`,
    joint: "Wax coated, click Lock",
    text: "Walnut Herringbone flooring adds a touch of luxury and modernity to your space.",
    color: "Medium brown",
    Code: "Brown 2024",
  },
  {
    id: 3,
    type: "Vintage Brown",
    hoverImage: img3,
    cover: img3,
    plank_dimension: `${103} * ${608} mm`,
    thickness: `${14} mm`,
    joint: "Wax coated, click Lock",
    // hoverImage: 'https://s3-eu-west-1.amazonaws.com/specifiedbypro/17219/44249/tomson-floors_engineered-herringbone-parquet-flooring-fumed-oak_photo_2_ca97ab3e-a7be-469b-bc56-a7bc19a26f71.jpeg',
    // cover: 'https://s3-eu-west-1.amazonaws.com/specifiedbypro/17219/44249/tomson-floors_engineered-herringbone-parquet-flooring-fumed-oak_photo_2_ca97ab3e-a7be-469b-bc56-a7bc19a26f71.jpeg',
    text: "Oak Carbonised flooring brings the beauty of nature into your home, making it a great choice for any room.",
    color: "H 005",
    Code: "Dark Brown 2024",
  },
  {
    id: 5,
    type: "Summer SoliTude",
    hoverImage: img4,
    cover: img4,
    plank_dimension: `${103} * ${608} mm`,
    thickness: `${14} mm`,
    joint: "Wax coated, click Lock",
    // hoverImage: 'https://www.directwoodflooring.co.uk/media/catalog/product/cache/c486f3c3ca16cb41d29ab6285e057f37/w/4/w47453j_main_1_1.jpg',
    // cover: 'https://www.directwoodflooring.co.uk/media/catalog/product/cache/c486f3c3ca16cb41d29ab6285e057f37/w/4/w47453j_main_1_1.jpg',
    text: "Oak Herringbone flooring combines aesthetics and durability, making it a great choice for any room.",
    color: "Natural",
    Code: "LN 04 H",
  },
  {
    id: 6,
    type: "Expresso Walnut ",
    hoverImage: img5,
    cover: img5,
    plank_dimension: `${103} * ${608} mm`,
    thickness: `${14} mm`,
    joint: "Wax coated, click Lock",
    // hoverImage: 'https://5.imimg.com/data5/SELLER/Default/2023/2/DL/EL/XY/690198/laminate-flooring-walnut-atlanta-fo-8151-500x500.JPG',
    // cover: 'https://5.imimg.com/data5/SELLER/Default/2023/2/DL/EL/XY/690198/laminate-flooring-walnut-atlanta-fo-8151-500x500.JPG',
    text: "Classic Walnut Herringbone flooring adds a touch of coastal charm and warmth to any space.",
    color: "Brown",
    Code: "6486",
  },
];

// Engineered planks-----------------------------------------------------------
export const Plank = [
  {
    id: 1,
    type: "American Walnut",
    cover: img32,
    hoverImage: img32,
    // hoverImage: 'https://i.pinimg.com/originals/87/65/06/876506f39f29b3ecb36f8969ed494546.png',
    text: "American Walnut plank flooring offers a classic and versatile look, perfect for any room.",
    color: "Light Brown",
    // cover: 'https://i.pinimg.com/originals/87/65/06/876506f39f29b3ecb36f8969ed494546.png',
    Code: "BRF 07",
    thickness: 14,
    plank_dimension: `${192} * ${1910} mm`,
    finish: "UV lacquered, Brushed finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  {
    id: 2,
    type: "American Walnut",
    cover: img8,
    hoverImage: img8,
    text: "American Walnut plank flooring adds a touch of luxury and modernity to your space.",
    color: "Light Brown",
    Code: "BRF 06",
    thickness: 14,
    plank_dimension: `${192} * ${1910} mm`,
    finish: "UV lacquered, Smooth finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  {
    id: 5,
    type: "American Walnut",
    cover: img10,
    hoverImage: img10,
    text: "American Walnut flooring combines aesthetics and durability, making it a great choice for any room.",
    color: "Dark brown",
    Code: "BRF 07",
    thickness: 14,
    plank_dimension: `${190} * ${1900} mm`,
    finish: "UV lacquered, Smooth finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  {
    id: 3,
    type: "Oak Carbonised",
    cover:img33 ,
    hoverImage: img33,
    // hoverImage: 'https://5.imimg.com/data5/ZP/FQ/WB/SELLER-56704558/oak-carbonised-dark-flooring.jpg',
    text: "Oak Carbonised plank flooring is durable and easy to maintain, making it a great choice for high-traffic areas.",
    color: "Dark brown",
    Code: "BRF 02",
    thickness: 14,
    plank_dimension: `${192} * ${1910} mm`,
    finish: "UV lacquered, Deep Brush finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  {
    id: 10,
    type: "Oak Carbonised",
    cover: img30,
    hoverImage: img30,
    text: "Oak Carbonised Light Brown flooring brings a warm, light tone to any room, combining aesthetics and durability.",
    color: "Light Brown",
    Code: "BRF 04",
    thickness: 14,
    veneer: "3 mm",
    plank_dimension: `${192} * ${1910} mm`,
    finish: "UV lacquered, Smooth finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  
  {
    id: 8,
    type: "Oak English",
    cover: img9,
    hoverImage: img9,
    text: "Oak English Grey flooring brings a touch of classic elegance to any room, combining aesthetics and durability.",
    color: "Grey",
    Code: "BRF 05",
    thickness: 14,
    veneer: "3 mm",
    plank_dimension: `${192} * ${1910} mm`,
    finish: "UV lacquered, Deep Brushed finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  {
    id: 9,
    type: "Oak Natural",
    cover: img11,
    hoverImage: img11,
    text: "Oak Natural flooring brings the beauty of nature into your home, combining aesthetics and durability.",
    color: "Natural",
    Code: "BRF 01",
    thickness: 14,
    veneer: "2 mm",
    plank_dimension: `${190} * ${1900} mm`,
    finish: "UV lacquered, Smooth finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  {
  id: 11,
  type: "Oak Natural Shade 2",
  cover: img12,
  hoverImage: img12,
  text: "Oak Natural Shade 2 flooring brings the beauty of nature into your home, combining aesthetics and durability.",
  color: "Natural",
  Code: "BRF 03",
  thickness: 14,
  veneer: "3 mm",
  plank_dimension: `${190} * ${1900} mm`,
  finish: "UV lacquered, Smooth",
  joint: "Wax coated, 5 G drop click Lock, beveled edge"
},
 
  {
    id: 4,
    type: "Kayu kuku",
    cover: 'https://cdn.archilovers.com/projects/b_730_93cd4b75-c8a0-4e68-ad65-6728e7aaa238.jpg',
    hoverImage: 'https://cdn.archilovers.com/projects/b_730_93cd4b75-c8a0-4e68-ad65-6728e7aaa238.jpg',
    text: "Kayu kuku plank flooring offers a unique and stylish look, perfect for adding character to your home or office.",
    color: "Medium brown",
    Code: "BRF K 01",
    thickness: 14,
    plank_dimension: `${152} * ${1810} mm`,
    finish: "UV lacquered, Smooth finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
  
  {
    id: 6,
    type: "Merbau Flooring",
    cover: 'https://www.westwoodfloorings.com/uploads/KAY_5296_60c11e5e09e98.jpg',
    hoverImage: 'https://www.westwoodfloorings.com/uploads/KAY_5296_60c11e5e09e98.jpg',
    text: "Merbau Flooring plank adds a touch of elegance and warmth to any space.",
    color: "Reddish brown",
    Code: "BRF MB 01",
    thickness: 14,
    plank_dimension: `${152} * ${1810} mm`,
    finish: "UV liquid, smooth finish",
    joint: "Wax coated, 5 G drop click Lock, beveled edge"
  },
];

//Hardware -----------------------------------------------------------
export const Hardwood = [
  {
    id: 1,
    type: "Oak Antique",
    cover: img13,
    text: "Oak Antique hardwood stairs offer a classic and versatile look, perfect for any home.",
    color: "Medium brown",
    hoverImage: 'https://europeanflooring.com/wp-content/uploads/2022/10/Brunello-1.jpg',
    Code: "BRF H 03",
    thickness: "18 mm",
    plank_dimension: "18*125*400-1200 mm",
    joint: "T & G Lock, beveled edge",
    finish: "UV lacquered, Matte finish"
  },
  
  {
    id: 4,
    type: "Oak Caramel",
    cover: img34,
    hoverImage: img31,
    text: "Oak Caramel hardwood floors offer a unique and stylish look, perfect for adding character to your home.",
    color: "Brown",

    Code: "BRF H 04",
    thickness: "18 mm",
    plank_dimension: "18*125*400-1200 mm",
    joint: "T & G Lock, beveled edge",
    finish: "UV lacquered, Brushed finish"
  },
  {
    id: 5,
    type: "Oak Natural",
    cover: img17,
    hoverImage: img17,
    text: "Oak Natural hardwood stairs combine aesthetics and durability, making them a great choice for any home.",
    color: "Yellowish brown",

    Code: "BRF H 02",
    thickness: "18 mm",
    plank_dimension: "18*125*400-1200 mm",
    joint: "T & G Lock, beveled edge",
    finish: "UV lacquered, Smooth finish"
  },
  {
    id: 6,
    type: "Okan",
    cover: img18,
    hoverImage: img18,
    text: "Okan hardwood floors add a touch of elegance and warmth to any space.",
    color: "Reddish brown",

    Code: "BRF H 01",
    thickness: "18 mm",
    plank_dimension: "18*122*910 mm",
    joint: "T & G Lock, beveled edge",
    finish: "UV lacquered, Smooth finish"
  },
];

//simple planks laminated-----------------------------------------------------------
export const laminatedplanks = [
  {
    id: 1,
    type: "Nature Sunshine",
    cover: img19,
    hoverImage: img19,
    // hoverImage: 'https://images.adsttc.com/media/images/6268/6424/3e4b/3197/fd00/0007/large_jpg/02PI_AP_PH_flo_home_diningroom_classic_wv4_EHC030_STL7_1.jpg?1651008530',
    text: "Nature Sunshine plank flooring offers a natural and vibrant look, perfect for any room.",
    color: "Natural",
    thickness: "8mm",
    plank_dimension: "200*1220 mm",
    joint: "Wax coated Click lock",
    finish: "AC 4, 2000 round tested",
    hdf: "825 HDF"
  },
  {
    id: 2,
    type: "Classic Walnut",
    cover: img20,
    hoverImage: img20,
    // hoverImage: 'https://i.pinimg.com/736x/10/5f/35/105f3586f0ff7f5e7a581aac54819c02.jpg',
    text: "Classic Walnut plank flooring adds a touch of classic elegance and modernity to your space.",
    color: "Light brown",
    thickness: "8mm",
    plank_dimension: "200*1220 mm",
    joint: "Wax coated Click lock",
    finish: "AC 4, 2000 round tested",
    hdf: "825 HDF"
  },
  {
    id: 3,
    type: "Oak Nature",
    cover: img21,
    hoverImage: img21,
    // hoverImage: 'https://i.pinimg.com/736x/49/8c/a6/498ca60a7351f388427c5fd7127c1b3a.jpg',
    text: "Oak Nature plank flooring brings the beauty of nature into your home, making it a great choice for any room.",
    color: "Brown",
    thickness: "8mm",
    plank_dimension: "200*1220 mm",
    joint: "Wax coated Click lock",
    finish: "AC 4, 2000 round tested",
    hdf: "825 HDF"
  },
  {
    id: 4,
    type: "Mocha Brown",
    cover: img22,
    hoverImage: img22,
    // hoverImage: 'https://hips.hearstapps.com/hmg-prod/images/edc050123studiokenyon-004-642dd32116e25.jpg?crop=1.00xw:0.725xh;0,0.275xh&resize=1200:*',
    text: "Mocha Brown plank flooring offers a unique and stylish look, perfect for adding a touch of sophistication to your home or office.",
    color: "Brown",
    thickness: "8mm",
    plank_dimension: "200*1220 mm",
    joint: "Wax coated Click lock",
    finish: "AC 4, 2000 round tested",
    hdf: "825 HDF"
  },
  {
    id: 5,
    type: "Summer Grey",
    cover: img23,
    hoverImage: img23,
    // hoverImage: 'https://laminateglasgow.com/cdn/shop/products/IMG_0653.jpg?v=1612186981',
    text: "Summer Grey plank flooring combines aesthetics and durability, making it a great choice for any room.",
    color: "Grey",
    thickness: "8mm",
    plank_dimension: "200*1220 mm",
    joint: "Wax coated Click lock",
    finish: "AC 4, 2000 round tested",
    hdf: "825 HDF"
  },
  {
    id: 6,
    type: "Coastal Wood",
    cover: img24,
    hoverImage: img24,
    // hoverImage: 'https://www.flooringamerica.com/root/clientImages/MF7979web/findmystyle/My%20Design%20Finder_1088.jpg',
    text: "Coastal Wood plank flooring adds a touch of coastal charm and warmth to any space.",
    color: "Natural ",
    thickness: "8mm",
    plank_dimension: "200*1220 mm",
    joint: "Wax coated Click lock",
    finish: "AC 4, 2000 round tested",
    hdf: "825 HDF"
  },
  {
  id: 7,
  type: "Rain Grey",
  cover: img25,
  hoverImage: img25,
  // hoverImage: 'https://i.pinimg.com/originals/02/f2/0e/02f20e6f6ec816fbc03a1363a03c17a2.png',
  text: "Rain Grey flooring brings a touch of modern sophistication to any space, with its unique grey tone.",
  color: "Grey",
  thickness: "8mm",
  plank_dimension: "200*1220 mm",
  joint: "Wax coated Click lock",
  finish: "AC 4, 2000 round tested",
  hdf: "825 HDF"
},
{
  id: 8,
  type: "Natural Teak",
  cover: img26,
  hoverImage: img26,
  // hoverImage: 'https://images.squarespace-cdn.com/content/v1/5384c1abe4b09192d2a9336d/1515449148396-INV6FGB5A72A19FF7ZUS/ambienteteak.jpg?format=1500w',
  text: "Natural Teak flooring brings the warmth and richness of natural wood to any space, with its varied color tones.",
  color: "Natural ",
  thickness: "8mm",
  plank_dimension: "200*1220 mm",
  joint: "Wax coated Click lock",
  finish: "AC 4, 2000 round tested",
  hdf: "825 HDF"
},
];

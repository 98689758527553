import React from "react"
// import img from "../images/3.jpeg"
// import Back from "../common/Back"
import "../home/featured/Featured.css"
// import FeaturedCard from "../home/featured/FeaturedCard"
import Recent from "../home/Services/Recent"

const Services = () => {
  return (
    <>
      <section className='services mb'>
        {/* <Back name='' title='Beringen Flooring' cover={img} /> */}
        <Recent />
        <div className='featured container'>
          {/* <FeaturedCard /> */}
        </div>
      </section>
    </>
  )
}

export default Services

import React, { useState, useRef, useEffect } from "react";
// import img from "../images/1.jpeg";
// import Back from "../common/Back";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  useEffect(() => {
    if (form.current) {
      form.current.reset();
    }
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(String(phone));
  };

  const handleSubmit = (e) => {
    if (!name || !email || !phone || !message) {
      e.preventDefault();
      alert("Please fill out all fields before submitting.");
    } else if (!validateEmail(email)) {
      e.preventDefault();
      alert("Please enter a valid email.");
    } else if (!validatePhone(phone)) {
      e.preventDefault();
      alert("Please enter a valid phone number.");
    } else {
      form.current.reset();
    }
  };

  return (
    <>
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          marginLeft: "7px",
        }}
      >
        Get Help & Support
      </h2>
      <section className="contact mb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-info email">
                <h5>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />{" "}
                  Address-(SWEDEN) :
                </h5>

                <p className="address-info">Woodpie AB Bäckadalsgatan 13</p>

                <p>543 32, Tibro Sverige, Sweden</p>

                <p className="phone-number">
                  <a href="tel:+46 73 504 61 23">
                    <FontAwesomeIcon icon={faMobileAlt} /> +46 73 504 61 23
                  </a>
                </p>
                <p className="email-link">
                  <a href="mailto:info@beringenflooring.com">
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    info@beringenflooring.com
                  </a>
                </p>
              </div>
              <div className="contact-info address">
                <h5>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />{" "}
                  Address-(INDIA) :
                </h5>
                <p className="address-info">
                  RZ 209, SAYYED NANGLOI NEW DELHI 110087{" "}
                </p>
                <p className="address-info">
                  (INDIA)
                </p>
                <p className="phone-number">
                  <a href="tel:+91-9999010957">
                    <FontAwesomeIcon icon={faMobileAlt} /> +91-9999010957
                  </a>
                </p>
                <p className="phone-number">
                  <a href="tel:+91-9811056183">
                    <FontAwesomeIcon icon={faMobileAlt} /> +91-9811056183
                  </a>
                </p>
                <p className="email-link">
                  <a href="mailto:Prashant_tcc@outlook.com">
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    Prashant_tcc@outlook.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <form
                className="shadow"
                action="https://beringenflooring.com/enquiry.php"
                method="POST"
                onSubmit={handleSubmit}
                ref={form}
              >
                <h4>Enquiry</h4> <br />
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="number"
                  name="phone"
                  placeholder="Phone no"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <textarea
                  cols="30"
                  rows="10"
                  name="message"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit" name="SubmitEmail">
                  Submit Enquiry
                </button>
              </form>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-12">
              <p>
                <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112125.30894958972!2d77.1237648572036!3d28.614179500000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0xfde2bea3406b4ba0!2sDelhi!5e0!3m2!1sen!2sin!4v1636116267193!5m2!1sen!2sin"
                  width="100%"
                  height={350}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
